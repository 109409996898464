
.header {
    ::v-deep {
        .logo {
            > a {
                background-image: url(/images/logo-dark.svg);
            }
        }
        .gnb--basic .gnb__link {
            color: var(--v-grey-darken4) !important;
        }
    }
}
