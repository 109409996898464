
::v-deep {
    .v-tabs {
        background-color: transparent !important;
        &:before {
            background-color: rgba(0, 0, 0, 0.5) !important;
        }
    }
    .v-tabs-slider {
        display: none;
    }
    .v-tab {
        color: #fff !important;
        &--active {
            background-color: #fff;
            color: var(--v-primary-base) !important;
        }
    }
}
